.page-item,
.page-item:first-child,
.page-link {
  border-radius: 14px !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  border: none;
  align-self: center;
}
