@import "./vars.scss";

* {
  scroll-behavior: smooth;
}

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "danger": $danger,
  "success": $success,
  "info": $info,
);

$border-radius: 1.5rem;
$border-radius-sm: 1rem;
$border-radius-md: 1rem;
$body-color: #3e4348;
// $input-bg: #6f3b8c10;
$input-btn-padding-y: 0.3rem;
$input-btn-padding-x: 1rem;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;
// $input-border-color: $primary;
// $input-focus-border-color: $primary;
$border-width: 0.15rem;
// $component-active-bg: $primary;
$btn-outline-color: #8b8b8b;
$btn-hover-color: white;
$modal-backdrop-bg: $primary;
$modal-backdrop-opacity: 0.9;
$modal-content-border-width: none;
$pagination-color: $primary;
$pagination-active-bg: $primary;
$pagination-bg: white;
$pagination-active-color: white;
$pagination-focus-color: $pagination-color;
$pagination-hover-color: $primary;
$pagination-hover-bg: $pagination-bg;
$pagination-focus-bg: $pagination-bg;
$form-select-indicator-color: $primary;
$pagination-padding-x: 0.8rem;
$pagination-padding-y: 0.2rem;
$pagination-border-color: transparent;
$pagination-hover-border-color: $primary;
$progress-bar-bg: $primary;
$font-size-base: 0.85rem;
$link-color: $primary;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 5,
  7: $spacer * 10,
  9: $spacer * 9,
  y: $input-btn-padding-y,
  x: $input-btn-padding-x,
);

$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
);

@import "bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

:root {
  --bs-body-font-family: "Poppins", sans-serif;
  --bs-primary: $primary;
}

* {
  font-family: "Poppins", sans-serif;
}

.form-label {
  font-weight: bold;
}

.input-group-text {
  background-color: $input-bg;
  line-height: normal !important;
  white-space: normal;
  text-align: start;
}

.form-check-label {
  font-weight: 500;
  margin-left: 1.4rem;
}

.form-check {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.form-check {
  .form-check-input {
    border-radius: 0.1rem !important;
    width: 1.2rem;
    height: 1.2rem;
    margin: 0px;
  }

  .form-check-input:checked {
    border-radius: 0.1rem;
    width: 1.2rem;
    height: 1.2rem;
  }
}

.form-switch.color-secondary {
  input {
    border-color: $secondary !important;
  }
}

.modal-content {
  padding: 2rem;
  border-radius: 1.3rem;
}

h4 {
  font-size: 1.35rem;
  line-height: 1.8rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #0000 inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $primary;
}

.horizontal-line {
  margin: 0px;
  border-width: 0.2rem;
  border-color: $primary;
  opacity: 0.6;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
}

.rounded-box {
  border-radius: 20px;
}

.rounded-circle {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
}

.rounded-circle.btn-sm {
  width: 2rem;
  height: 2rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: 1rem !important;
    min-height: 1rem !important;
    width: 1rem !important;
    height: 1rem !important;
  }
}

.btn-secondary {
  color: $primary !important;
}

.react-calendar {
  border-radius: 20px;
  background-color: $primary !important;
  color: white !important;
  padding: 1rem;
  user-select: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button,
.react-calendar__navigation__label,
.react-calendar__tile,
.react-calendar__tile--now {
  border-radius: 500rem;
  color: white !important;
  width: 35px !important;
  height: 35px !important;
  padding: 0px;
  margin: 0px;
  background-color: transparent !important;
}

.react-calendar__tile:disabled {
  color: #fff2 !important;
}

.react-calendar__tile--now {
  background-color: #fdc02822 !important;
}

.react-calendar__navigation__prev-button:hover,
.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__label:hover,
.react-calendar__tile:hover {
  background-color: #fff2 !important;
  :disabled {
    background-color: transparent !important;
  }
}

.react-calendar__navigation__prev-button:disabled:hover,
.react-calendar__navigation__next-button:disabled:hover,
.react-calendar__navigation__label:disabled:hover,
.react-calendar__tile:disabled:hover {
  background-color: transparent !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #fff2 !important;
}

.react-calendar__month-view__weekdays__weekday {
  abbr {
    text-decoration: none !important;
    cursor: default;
  }
}

.react-calendar__tile--active {
  background-color: #fdc028 !important;
  color: $primary !important;
  font-weight: bold;
}

.react-calendar__tile--active:hover {
  background-color: #fdc028 !important;
  color: $primary !important;
  font-weight: bold;
}

.form-select {
  gap: 1rem;

  option {
    border-radius: 30px;
    padding: 2px 6px 2px 6px;
    margin-bottom: 2px;
  }
}

.color-gray {
  --bs-text-opacity: 1;
  color: #3e4348 !important;
}

.test-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}

.test-scroll::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
}

.test-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.fw-bold {
  font-weight: 600 !important;
}

.fw-bolder {
  font-weight: 800 !important;
}

@keyframes popIn {
  0% {
    display: none;
    height: 0vh;
    overflow: hidden;
  }
  99% {
    display: none;
    height: 99vh;
    overflow: hidden;
  }
  100% {
    display: flex;
    height: 100vh;
    overflow: auto;
  }
}

.pop-in {
  animation: popIn 0.7s forwards;
}

@keyframes popOut {
  0% {
    height: 100vh;
  }
  100% {
    height: 0px;
    overflow: hidden;
  }
}

.pop-out {
  animation: popOut 0.4s forwards;
}

.react-select__control {
  border-radius: 30px !important;
  border-color: $input-border-color !important;
  border-width: $border-width !important;
  background-color: $input-bg !important;
  color: $primary !important;
  min-height: 25px !important;
}

.react-select__input,
.react-select__single-value,
.react-select__indicator {
  color: $primary !important;
  border-radius: 40px;
}

.react-select__menu {
  min-width: 200px !important;
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.btn-primary {
  line-height: normal !important;
  &:active {
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
  }
}

.btn-outline-primary {
  background-color: $input-bg;
  color: black;
  line-height: normal !important;
  &:active {
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
  }
  &:disabled {
    background-color: #f1f1f1;
    color: $btn-outline-color;
  }
}

.btn-outline-secondary {
  line-height: normal !important;
  color: $secondary !important;
  &:hover {
    border-color: $secondary !important;
  }
  &:active {
    background-color: $secondary !important;
    border-color: $secondary !important;
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
    color: $primary !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus-visible {
    color: $primary !important;
  }
}

.btn-outline-secondary:hover {
  color: #6f3b8c !important;
}

.btn-outline-warning {
  line-height: normal !important;
  &:hover {
    color: $primary !important;
    border-color: $secondary !important;
  }
  &:active {
    background-color: $secondary !important;
    border-color: $secondary !important;
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
    color: $primary !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus-visible {
    color: $primary !important;
  }
}

.btn-outline-info {
  line-height: normal !important;
  &:hover {
    color: white !important;
    border-color: $info !important;
  }
  &:active {
    background-color: $info !important;
    border-color: $info !important;
    box-shadow: rgba($info, 0.5) 0px 0px 0px 4px !important;
    color: white !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($info, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus-visible {
    color: white !important;
  }
}

.btn-outline-danger {
  line-height: normal !important;
  &:hover {
    color: white !important;
    border-color: $danger !important;
  }
  &:active {
    background-color: $danger !important;
    border-color: $danger !important;
    box-shadow: rgba($danger, 0.5) 0px 0px 0px 4px !important;
    color: white !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($danger, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus-visible {
    color: white !important;
  }
}

.btn-outline-success {
  line-height: normal !important;
  &:hover {
    color: white !important;
    border-color: $success !important;
  }
  &:active {
    background-color: $success !important;
    border-color: $success !important;
    box-shadow: rgba($success, 0.5) 0px 0px 0px 4px !important;
    color: white !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($success, 0.5) 0px 0px 0px 4px !important;
  }
  &:focus-visible {
    color: white !important;
  }
}

.btn-primary {
  line-height: normal !important;
  &:active {
    background-color: $primary !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
    border-color: $primary !important;
  }
}

.btn-secondary {
  line-height: normal !important;
  &:active {
    background-color: $secondary !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
    border-color: $secondary !important;
  }
}

.btn-info {
  line-height: normal !important;
  &:active {
    background-color: $info !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($info, 0.5) 0px 0px 0px 4px !important;
    border-color: $info !important;
  }
}

.btn-danger {
  line-height: normal !important;
  color: white !important;
  &:active {
    background-color: $danger !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($danger, 0.5) 0px 0px 0px 4px !important;
    border-color: $danger !important;
  }
}

.btn-success {
  line-height: normal !important;
  color: white !important;
  &:active {
    background-color: $success !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($success, 0.5) 0px 0px 0px 4px !important;
    border-color: $success !important;
  }
}

.btn-info {
  line-height: normal !important;
  color: white !important;
  &:active {
    background-color: $info !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($info, 0.5) 0px 0px 0px 4px !important;
    border-color: $info !important;
  }
}

.btn-warning {
  line-height: normal !important;
  color: $primary !important;
  &:active {
    background-color: $secondary !important;
  }
  &:focus,
  &:focus-visible {
    box-shadow: rgba($secondary, 0.5) 0px 0px 0px 4px !important;
    border-color: $secondary !important;
  }
}

.btn-light {
  line-height: normal !important;
  color: $primary !important;
  border: none !important;
  background-color: transparent;
  &:hover {
    background-color: transparent;
    border: none !important;
  }
  &:active {
    background-color: transparent !important;
  }
  &:focus,
  &:focus-visible {
    background-color: transparent !important;
    box-shadow: rgba($primary, 0.5) 0px 0px 0px 4px !important;
    border-color: $primary !important;
  }
}

.btn-primary.dropdown-toggle {
  background-color: $primary !important;
  border-color: $primary !important;
}

.list-group {
  gap: 0.4rem !important;
  border-color: $primary !important;
  border-width: $border-width !important;
  border-style: solid !important;
  > * {
    border-radius: 0px !important;
  }
  overflow: hidden;
  background-color: rgba($primary, 0.5);
}

.list-group-item {
  border: none !important;
}

.fc-button {
  background-color: $primary !important;
  border-color: $primary !important;
}

.fc-day-today {
  background-color: #d9e5f0 !important;
}

.tlf-left-sidebar {
  max-height: calc(100vh - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  a {
    text-decoration: none;
    font-size: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    &:hover {
      color: $primary;
      font-weight: 600;
    }
  }
}

.table-responsive {
  th {
    border-top: none;
  }
}

.bobyContentBackgroundColor {
  background-color: #f2f2f2;
}

.load-fixed {
  height: 10px;
}
