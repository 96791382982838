// Styles

// Main styles

@import "./config/default/bootstrap.scss";
@import "./config/default/app.scss";

// Hyper Icons
@import "./icons.scss";

.modal {
  background-color: #00000044;
}
