.ql-container {
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 0px;
  border-color: var(--bs-primary) !important;
  border-width: var(--bs-border-with) !important;
  background-color: var(--bs-input-bg) !important;
}
.ql-toolbar {
  border-color: var(--bs-primary) !important;
  border-width: var(--bs-border-with) !important;
  background-color: var(--bs-input-bg) !important;
}
