.list__container-table {
width: 100%;
margin: 0;
}

.item-list {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  list-style: none;

  li {
    margin-top: 1rem;

    .item-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &:not(:last-child) {
      hr {
        margin: 8px 0;
      }
    }
  }
}