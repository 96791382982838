//
// _react-select.scss
//

.react-select {
  > div {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg !important;

    &:hover {
      border: $input-border-width solid $input-border-color;
    }
  }
}

.react-select__control--is-focused {
  border-color: $input-focus-border-color !important;
  box-shadow: none !important;
}

.react-select__dropdown-indicator {
  color: $gray-600 !important;
}

.react-select__menu {
  background-color: $dropdown-bg !important;
  box-shadow: none !important;
  border: none !important;
  border-radius: $dropdown-border-radius !important;
  z-index: $zindex-dropdown !important;
}

.react-select__menu-list {
  border: 1px solid rgb(191, 191, 191);
  color: black;
  background-color: #ffffff;
  border-radius: $border-radius-sm !important;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 5px 5px;
}

.react-select__option {
  background-color: transparent !important;
  transition: background-color 0.3s; /* Adiciona uma transição suave */

  &:hover {
    background-color: rgba($primary, 0.2) !important;
  }

  cursor: pointer !important;
}

.react-select__option--is-selected {
  color: $dark !important;
  background-color: transparent !important;
}
.react-select__single-value {
  color: $gray-600 !important;
}
.react-select__multi-value {
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;

  .react-select__multi-value__label {
    color: $primary;
  }

  .react-select__multi-value__remove {
    &:hover {
      background-color: rgba($primary, 0.2);
      color: $primary;
    }
  }
}

react-bootstrap-typeahead style .rbt-token {
  background-color: rgba($primary, 0.2) !important;
  color: $primary !important;
  .rbt-token-remove-button {
    background-color: transparent;
    border: none;
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border: 0;
    }
  }
}

.rbt-input-multi.focus {
  box-shadow: none !important;
  background-color: $input-focus-bg !important;
  border-color: $input-focus-border-color !important;
}

custom select .react-select-container {
  .react-select__value-container {
    &--has-value {
      .react-select__single-value {
        color: $input-color !important;
      }
    }
  }
  .react-select__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x !important;
    color: $gray-700 !important;
    &:hover {
      color: $white !important;
      background-color: $primary !important;
    }
  }
}

Topbar search .app-search {
  .react-select__menu-list {
    .react-select__option {
      &:hover {
        .dropdown-item {
          color: $dark;
          background-color: $dropdown-link-hover-bg;
        }
      }
      &.react-select__option--is-disabled {
        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  .react-select__value-container {
    &--has-value {
      .react-select__single-value {
        i,
        img,
        .user-subinfo {
          display: none !important;
        }
        .dropdown-item {
          color: rgba($white, 0.6) !important;
          .drop-username {
            color: rgba($white, 0.6) !important;
          }
          &:hover {
            color: $dropdown-link-color;
            background-color: transparent;
          }
        }
      }
    }
  }
}
