$primary: #6f3b8c;
$secondary: #fdc028;
$tertiary: #F26C56;
$danger: #ed5046;
$success: #3ac965;
$info: #5ea8d9;

:export {
  primary: $primary;
  secondary: $secondary;
  danger: $danger;
  success: $success;
  info: $info;
}
